import { useEffect, useState } from 'react'

/**
 * コンポーネントが可視状態になる度にtrueになる
 * @param ref
 * @returns
 */
export default function useOnScreen(
  ref: React.MutableRefObject<Element | null | undefined>
) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}
