import {
  Button,
  Flex,
  HStack,
  Image,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Box,
  Center,
} from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'
import { DisplayPlan, numberWithCommas } from '@gijirokukun/shared'
import { PlanCard } from './PlanCard'
import useOnScreen from 'src/hooks/useOnScreen'

export const Pricing: React.FC<{
  PlanButton: React.FC<{ plan: DisplayPlan; dataTest: string }>
  defaultTab: 'personal' | 'organization' | undefined
  selectedPlan?: DisplayPlan
  onChangeExpanded: (isPlanCardExpanded: boolean) => void
  onVisible: () => void
}> = ({
  PlanButton,
  onChangeExpanded,
  onVisible,
  selectedPlan,
  defaultTab,
}) => {
  // 価格表がExpandされているかどうか
  const [isPlanCardExpanded, _setIsPlanCardExpanded] = useState(false)
  const setIsPlanCardExpanded = (isExpanded: boolean) => {
    onChangeExpanded(isExpanded)
    _setIsPlanCardExpanded(isExpanded)
  }

  // 価格表が画面に表示されているかどうか
  const ref = useRef<HTMLDivElement | null>(null)
  const isVisible = useOnScreen(ref)
  useEffect(() => {
    if (isVisible) {
      onVisible()
    }
  }, [isVisible])

  // プランデータ
  const organizationV2EnterprisePlan = new DisplayPlan(
    'OrganizationV2Enterprise'
  )
  const personalStandardPlan = new DisplayPlan('PersonalStandard')

  if (defaultTab === undefined) {
    return <></>
  }

  return (
    <Flex ref={ref} flexDir="column">
      <Spacer flexBasis="2rem" />
      <VStack align="center" spacing="2rem">
        <Box display={{ base: 'none', md: 'block' }} w="full">
          <VStack spacing="3.5rem" w="full">
            <VStack spacing="1rem" w="full">
              <HStack w="full" spacing="0.875rem">
                {[organizationV2EnterprisePlan, personalStandardPlan].map(
                  (plan) => {
                    return (
                      <PlanCard
                        dataTest="lp-pricing-card-stack"
                        plan={plan}
                        key={plan.serialize()}
                        isExpanded={isPlanCardExpanded}
                        PlanButton={PlanButton}
                      />
                    )
                  }
                )}
              </HStack>
              <Flex h="3rem" flexFlow="column">
                <Text fontSize="0.75rem" color="#555">
                  ※1 議事録収録時間を超えた場合、
                  1時間880円（税込）の従量課金となります。
                </Text>
                <Text fontSize="0.75rem" color="#555">
                  ※2 議事録収録時間を超えた場合、
                  1分あたり22円（税込）の従量課金となります。
                </Text>
                <Text fontSize="0.75rem" color="#555">
                  ※3 無制限キャンペーン実施中
                </Text>
              </Flex>
            </VStack>

            <ExpandButton
              isExpanded={isPlanCardExpanded}
              setIsExpanded={setIsPlanCardExpanded}
            />
          </VStack>
        </Box>

        <Box display={{ base: 'block', md: 'none' }} w="full">
          <Tabs
            variant="soft-rounded"
            w="full"
            isFitted
            defaultIndex={
              selectedPlan
                ? selectedPlan.isOrganizationMember
                  ? 0
                  : 1
                : defaultTab === 'organization'
                ? 0
                : 1
            }
          >
            <TabList
              borderWidth="0.125rem"
              borderColor="lightGray"
              borderRadius="2.125rem"
              p="0.125rem"
              mb="0.625rem"
            >
              <Tab data-test="company-use">Enterprise</Tab>
              <Tab data-test="personal-use">Personal</Tab>
            </TabList>
            <TabPanels>
              {[organizationV2EnterprisePlan, personalStandardPlan].map(
                (plan) => {
                  return (
                    <TabPanel key={plan.serialize()} p={0}>
                      <VStack spacing="0.55555555555rem" w="full">
                        <PlanCard
                          dataTest="lp-pricing-card-tab"
                          plan={plan}
                          isExpanded={isPlanCardExpanded}
                          PlanButton={PlanButton}
                        />
                        <Flex h="6rem" flexFlow="column">
                          <Text fontSize="0.75rem" color="#555">
                            ※1 議事録収録時間を超えた場合、
                            1時間880円（税込）の従量課金となります。
                          </Text>
                          <Text fontSize="0.75rem" color="#555">
                            ※2 議事録収録時間を超えた場合、
                            1分22円（税込）の従量課金となります。
                          </Text>
                          <Text fontSize="0.75rem" color="#555">
                            ※3 無制限キャンペーン実施中
                          </Text>
                        </Flex>
                        <ExpandButton
                          isExpanded={isPlanCardExpanded}
                          setIsExpanded={setIsPlanCardExpanded}
                        />
                      </VStack>
                    </TabPanel>
                  )
                }
              )}
            </TabPanels>
          </Tabs>
        </Box>
      </VStack>
    </Flex>
  )
}

const ExpandButton: React.FC<{
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
}> = ({ isExpanded, setIsExpanded }) => {
  return (
    <Button
      colorScheme="black"
      variant="solid"
      w="15.06rem"
      h="3rem"
      borderRadius="2rem"
      rightIcon={
        <Image
          src={
            isExpanded
              ? '/landing/Icon/up-arrow.svg'
              : '/landing/Icon/down-arrow.svg'
          }
        />
      }
      iconSpacing="auto"
      onClick={() => {
        setIsExpanded(!isExpanded)
      }}
    >
      <Text
        fontWeight="bold"
        fontSize="0.875rem"
        lineHeight="none"
        color="white"
        marginInlineStart="auto"
        cursor="pointer"
      >
        {isExpanded ? '閉じる' : 'もっと詳しくみる'}
      </Text>
    </Button>
  )
}
