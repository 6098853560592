import { Flex, HStack, Image, Text, VStack, Collapse } from '@chakra-ui/react'
import React from 'react'
import { numberWithCommas } from '@gijirokukun/shared'
import { DisplayPlan } from '@gijirokukun/shared'

const Ok = () => <Image src="/landing/Icon/maru.svg" boxSize="1rem" />

/**
 * ユーザーに見せる用のプランデータ。一部加工してある。
 * @param plan
 * @returns
 */
export const featureListForPresentation = (plan: DisplayPlan) =>
  [
    [
      '１ヶ月当たりの定額議事録収録時間',
      plan.feature.canCreateMinutes
        ? plan.feature.maxHoursPerCycle === Infinity
          ? '無制限'
          : `${plan.feature.maxHoursPerCycle}時間${
              plan.isOrganizationMember ? '～幅広いプランを用意' : ''
            }`
        : '作成不可',
    ],
    [
      '契約期間',
      plan.type === 'OrganizationV2Enterprise' ? '月間・半年・年間' : '月間',
    ],
    [
      '決算方法',
      plan.type === 'OrganizationV2Enterprise'
        ? '請求書/銀行振込'
        : 'クレジットカード',
    ],
    [
      '話者識別機能',
      // eslint-disable-next-line react/jsx-key
      <Ok />,
    ],
    [
      'Zoom連携',
      // eslint-disable-next-line react/jsx-key
      plan.feature.isZoomIntegrationEnabled ? <Ok /> : '-',
    ],
    [
      'Microsoft Teams連携',
      // eslint-disable-next-line react/jsx-key
      plan.canEnableMSTeamsIntegration ? <Ok /> : '-',
    ],
    [
      'Google Meet連携',
      // eslint-disable-next-line react/jsx-key
      plan.feature.isMeetIntegrationEnabled ? <Ok /> : '-',
    ],
    [
      'ファイルインポート',
      // eslint-disable-next-line react/jsx-key
      plan.feature.isAudioFileImportEnabled ? <Ok /> : '-',
    ],
    [
      '単語登録',
      // eslint-disable-next-line react/jsx-key
      <Ok />,
    ],
    [
      'テキスト編集',
      // eslint-disable-next-line react/jsx-key
      <Ok />,
    ],
    [
      '発言の自動翻訳',
      // eslint-disable-next-line react/jsx-key
      <Ok />,
    ],
    [
      'フォルダ機能',
      // eslint-disable-next-line react/jsx-key
      <Ok />,
    ],
    [
      '議事録のダウンロード',
      // eslint-disable-next-line react/jsx-key
      <Ok />,
    ],
    [
      'すぐに使える議事録テンプレート',
      // eslint-disable-next-line react/jsx-key
      <Ok />,
    ],
    [
      'カスタマーサポート',
      // eslint-disable-next-line react/jsx-key
      plan.feature.customerSupport ? <Ok /> : '-',
    ],
    [
      '作成できるチーム数',
      `${plan.feature.maxTeams === 0 ? '-' : `${plan.feature.maxTeams}個`}`,
    ],
    [
      'チームに参加できる人数',
      plan.feature.maxTeamMembers > 0
        ? `${plan.feature.maxTeamMembers}人/1チーム`
        : '-',
    ],
    ['ユーザーの管理', plan.feature.memberManage ? <Ok /> : '-'],
    [
      '音声の最大保存容量',
      `${
        plan.feature.maxAudioStorageCapacityMB === Infinity
          ? '無制限'
          : plan.feature.maxAudioStorageCapacityMB < 1024
          ? `${Math.floor(plan.feature.maxAudioStorageCapacityMB)}MB`
          : `${
              Math.floor((plan.feature.maxAudioStorageCapacityMB / 1024) * 10) /
              10
            }GB`
      }`,
    ],
  ] as const

const PLAN_CARD_DEFAULT_VISIBLE_NUM = 5

const CONSUMPTION_TAX_RATE = 1.1 // 消費税率

export const PlanCard: React.FC<{
  plan: DisplayPlan
  isExpanded: boolean
  PlanButton: React.FC<{ plan: DisplayPlan; dataTest: string }>
  dataTest: string
}> = ({ plan, isExpanded, PlanButton, dataTest }) => {
  return (
    <VStack
      w="100%"
      key={plan.serialize()}
      borderWidth="0.125rem"
      borderColor="lightGray"
      borderRadius="2.125rem"
      py={{ base: '2rem', md: '2.5rem' }}
      spacing={{ base: '1.5rem', md: '1.875rem' }}
    >
      <Text
        fontFamily="Rubik"
        fontWeight="bold"
        fontSize={{ base: '1.75rem', lg: '2rem' }}
        lineHeight="none"
      >
        {plan.displayName}
      </Text>
      <VStack>
        <HStack>
          <Text
            fontFamily="Rubik"
            fontWeight="bold"
            fontSize={{ base: '0.75rem', lg: '1rem' }}
            lineHeight="none"
            marginTop="1.5rem"
          >
            月額
          </Text>

          <Text
            fontFamily="Rubik"
            fontWeight="bold"
            fontSize={{ base: '3rem', lg: '3.5rem' }}
            lineHeight="none"
            height="2.5rem"
          >
            {
              // FIXME: 円の横棒の本数がおかしい https://aidealabhq.slack.com/archives/C024E4FVA3B/p1625022755081600?thread_ts=1625022348.079800&cid=C024E4FVA3B
            }
            ¥{numberWithCommas(plan.displayTaxExcludedPrice.baseAmount)}
            {plan.displayTaxExcludedPrice.isLowerLimit ? '〜' : ''}
          </Text>
          <Text
            fontFamily="Rubik"
            fontWeight="bold"
            fontSize={{ base: '0.75rem', lg: '1rem' }}
            lineHeight="none"
            marginTop="1.5rem"
          >
            （税別）
          </Text>
        </HStack>
        <Text fontSize="0.75rem" color="#555">
          ¥
          {numberWithCommas(
            Math.floor(
              plan.displayTaxExcludedPrice.baseAmount * CONSUMPTION_TAX_RATE
            )
          )}
          {plan.displayTaxExcludedPrice.isLowerLimit ? '〜' : ''}
          （税込）
        </Text>
      </VStack>
      <PlanButton plan={plan} dataTest={dataTest} />
      <Flex flexDir="column">
        <VStack spacing={{ base: '1rem', md: '1.5rem' }}>
          {featureListForPresentation(plan)
            .slice(0, PLAN_CARD_DEFAULT_VISIBLE_NUM)
            .map((feature, i) => {
              return (
                <VStack spacing="0.5rem" key={`${plan.serialize()}-${i}`}>
                  <Text color="gray" fontSize="0.875rem" lineHeight="none">
                    {feature[0]}
                  </Text>
                  <Flex pos="relative">
                    {typeof feature[1] === 'string' ? (
                      <Text fontWeight="bold" fontSize="1rem" lineHeight="none">
                        {feature[1]}
                      </Text>
                    ) : (
                      feature[1]
                    )}
                    {feature[0] === '１ヶ月当たりの定額議事録収録時間' &&
                      (plan.type === 'OrganizationV2Enterprise' ? (
                        <Text
                          fontWeight="bold"
                          fontSize="0.5rem"
                          lineHeight="none"
                          position="absolute"
                          right="-1rem"
                        >
                          ※1
                        </Text>
                      ) : (
                        <Text
                          fontWeight="bold"
                          fontSize="0.5rem"
                          lineHeight="none"
                          position="absolute"
                          right="-1rem"
                        >
                          ※2
                        </Text>
                      ))}
                  </Flex>
                  {feature[0] === '契約期間' &&
                    plan.type === 'OrganizationV2Enterprise' && (
                      <Text
                        fontWeight="bold"
                        fontSize="0.75rem"
                        lineHeight="none"
                      >
                        ※半年以上の場合は割引あり
                      </Text>
                    )}
                </VStack>
              )
            })}
        </VStack>
        <Flex mb={{ base: '1rem', md: '1.5rem' }}></Flex>
        <Collapse in={isExpanded}>
          <VStack spacing={{ base: '1rem', md: '1.5rem' }}>
            {featureListForPresentation(plan)
              .slice(
                PLAN_CARD_DEFAULT_VISIBLE_NUM,
                featureListForPresentation(plan).length
              )
              .map((feature, i) => {
                return (
                  <VStack spacing="0.5rem" key={`${plan.serialize()}-${i}`}>
                    <Text color="gray" fontSize="0.875rem" lineHeight="none">
                      {feature[0]}
                    </Text>
                    <Flex pos="relative">
                      {typeof feature[1] === 'string' ? (
                        <Text
                          fontWeight="bold"
                          fontSize="1rem"
                          lineHeight="none"
                        >
                          {feature[1]}
                        </Text>
                      ) : (
                        feature[1]
                      )}
                      {feature[0] === '音声の最大保存容量' &&
                        plan.feature.isStorageUnlimited && (
                          <Text
                            fontWeight="bold"
                            fontSize="0.5rem"
                            lineHeight="none"
                            position="absolute"
                            right="-1rem"
                          >
                            ※3
                          </Text>
                        )}
                    </Flex>
                  </VStack>
                )
              })}
          </VStack>
        </Collapse>
      </Flex>
    </VStack>
  )
}
